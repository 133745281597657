import { apiFactory } from '../../api/apiFactory'

const lessonsApi = apiFactory.get('lessons')

const state = {
	lesson: null,
}

const getters = {
	lesson: state => state.lesson,
}

const mutations = {
	SET_LESSON(state, payload) {
		state.lesson = payload
	},
}

const actions = {
	/**
	 * Load lesson
	 * @param {Object} param0 store
	 * @param {Number} id lesson id
	 */
	async loadLesson({ commit }, id) {
		// eslint-disable-next-line no-useless-catch
		try {
			const { data } = await lessonsApi.get(id)
			return commit('SET_LESSON', data)
		} catch (error) {
			throw error
		}
	},
	/**
	 * Unload lesson from store
	 * @param {*} param0 store
	 */
	unloadLesson({ commit }) {
		commit('SET_LESSON', null)
	},
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
