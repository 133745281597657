import router from '../../router'
import CartProduct from '../../models/CartProduct'

const getters = {
	/**
	 * Return product items listed in cart
	 */
	items: () => CartProduct.all(),

	/**
	 * Returns if cart is empty
	 */
	isEmpty: (state, { items }) => items.length === 0,

	/**
	 * Returns total products quantity in cart
	 */
	productsQuantity: (state, { items }) => items.reduce((current, { quantity }) => current + quantity, 0),

	/**
	 * Returns total amount of all products in cart
	 * @returns Number
	 */
	totalAmount: (state, { items }) => items.reduce((current, { total_amount }) => current + total_amount, 0),
}

const actions = {

	/**
	 * Add Product to Cart
	 * @param {Object} store store
	 * @param {Object} product Product
	 */
	addToCart(store, product) {

		const model = CartProduct.find(product.id)
		
		if (model) {
			model.increment()
		} else {
			const data = {
				...product,
				quantity: 1,
			}
			return CartProduct.insert({ data })
		}
	},

	/**
	 * Remove all Products from Cart
	 */
	clearCart() {
		return CartProduct.deleteAll()
	},

	/**
	 * Check if cart is not empty and
	 * suggest user to continue payment
	 */
	async notifyCartNonEmpty({ getters }) {
		if (!getters.isEmpty) {
			const confirm = await this._vm.$swal('confirm', {
				title: 'У тебе є назавeршене замовлення в кошику',
				content: 'Хочеш перейти у кошик, щоб оплатити замовлення?',
				icon: '🥳',
				buttonCancel: {
					text: 'Та не треба',
				},
				buttonOk: {
					text: 'Звісно!',
				},
			})

			if (confirm) {
				router.push({ name: 'cart' })
			}
		}
	},
}

export default {
	namespaced: true,
	getters,
	actions,
}
