import VuexPersistence from 'vuex-persist'
import { pick } from 'lodash'

const MODULES = [
	'auth',
	'entities.cartProducts',
	'test',
]

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	reducer: (state) => {
		return pick(state, MODULES)
	},
})

export default vuexLocal.plugin