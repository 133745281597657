import dexie from 'dexie'
import api from '../api/api'

const db = new dexie('saved_requests')

db.version(1).stores({
    requests: '++id, url, type, payload',
})

export default {
	
	/**
	 * Store request in db
	 * @param {String} url request api url
	 * @param {String} method method of request, e.g. POST, PUT
	 * @param {Object} data request body
	 */
	store(url, method, data) {
		db.requests.put({
			url,
			method,
			data,
		})
	},

	/**
	 * Resend all requests which are stored in DB
	 */
	async resendAll() {

		const requests = await db.requests.toArray()

		requests.forEach(async(request) => {
			try {
				await api({...request, data: JSON.parse(request.data)})
				await db.requests.delete(request.id)
				console.warn(`🧤request ${request.id} sent and deleted locally`)
				console.warn(`🧤request ${request.id} sent and deleted locally`)
			} catch (error) {
				console.error(error)
			}
		})
	},
}