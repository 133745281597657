import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import uk from 'vuetify/es5/locale/uk'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: colors.amber,
				secondary: '#4DCB85',
				success: '#4DCB85',
				accent: '#4DCB85',
				error: '#FC545B',
				// info: '#2196F3',
				// success: '#4CAF50',
				// warning: '#FFC107'
				btnBg: '#000',
				btnTxt: '#FFF',
			},
			dark: {
				primary: colors.amber,
				secondary: '#4DCB85',
				success: '#4DCB85',
				accent: '#4DCB85',
				error: '#FC545B',
				btnBg: '#FFF',
				btnTxt: '#000',
			},
		},
	},
	lang: {
		locales: {
			uk,
		},
		current: 'uk',
	},
	icons: {
		iconfont: 'mdi',
	},
})