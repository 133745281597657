import api from './api'

const resource = 'lessons'

export default {
	
	/**
	 * Get lessons by Course id
	 * @param {Number} courseid Course id
	 * @param {Object} params Query params
	 */
	getByCourse(courseid, params) {
		return api.get(`courses/${courseid}/${resource}`, {
			params,
		})
	},
	
	/**
	 * Get by id
	 * @param {Number} lessonid
	 */
	get(lessonid) {
		return api.get(`${resource}/${lessonid}`)
	},
}