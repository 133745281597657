import api from './api'

const resource = '/completed-lessons'

export default {
	
	/**
	 * Attach completed lesson
	 * @param {Number} lessonid lessons id
	 */
	create(lessonid) {
		return api.post(`${resource}`, [ lessonid ])
	},

	/**
	 * Detach completed lesson
	 * @param {Number} lessonid lesson id
	 */
	delete(lessonid) {
		return api.delete(`${resource}/${lessonid}`)
	},
}