/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable eqeqeq */
/* eslint-disable no-prototype-builtins */
export default {
	install (Vue) {

		Vue.prototype.deepEqualObj = function(a, b) {

			if (a === b) return true

			if (a == null || typeof a !== 'object' ||
				b == null || typeof b !== 'object')
				return false

			let propsA = 0,
				propsB = 0

			for (let prop in a) {
				if (a.hasOwnProperty(prop)) {
					propsA += 1
				}
			}

			for (let prop in b) {
				if (b.hasOwnProperty(prop)) {
					propsB += 1
					if (!(prop in a) || !Vue.prototype.deepEqualObj(a[prop], b[prop]))
						return false
				}
			}

			return propsA === propsB
		}

		Vue.prototype.deepEqualArr = function(a, b) {

			if (a === b) return true

			if (a == null || typeof a !== 'object' ||
				b == null || typeof b !== 'object')
				return false

			if (a.length != b.length) return false

			a.sort()
			b.sort()

			for (let i = 0; i < a.length; i++) {
				if (!Vue.prototype.deepEqualArr(a[i], b[i])) {
					return false
				}
			}
			
			return true
		}
	},

}