import VuexORM from '@vuex-orm/core'
import Subject from '../../models/Subject'
import Course from '../../models/Course'
import CourseSection from '../../models/CourseSection'
import Exam from '../../models/Exam'
import Product from '../../models/Product'
import Order from '../../models/Order'
import CartProduct from '../../models/CartProduct'
import Student from '../../models/Student'
import Result from '../../models/Result'

// Create a new instance of Database
const database = new VuexORM.Database()

// Register Models to Database
database.register(Subject)
database.register(Course)
database.register(CourseSection)
database.register(Exam)
database.register(Product)
database.register(Order)
database.register(CartProduct)
database.register(Student)
database.register(Result)

export default VuexORM.install(database)