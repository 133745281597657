/* eslint-disable camelcase */
import ApiModel from './ApiModel'

/**
 * Subject model
 */
class Subject extends ApiModel {

	static entity = 'subjects'

	static apiResource = 'subjects'

	static fields () {
		return {
			id: this.number(),
			name: this.string(),
			cover_img: this.string(),
		}
	}
}

export default Subject