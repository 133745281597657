import authApi from './authApi'
import subjectsApi from './subjectsApi'
import coursesApi from './coursesApi'
import courseSectionsApi from './courseSectionsApi'
import lessonsApi from './lessonsApi'
import completedLessonsApi from './completedLessonsApi'
import usersApi from './usersApi'
import testsApi from './testsApi'
import examsApi from './examsApi'
import resultsApi from './resultsApi'
import examResultsApi from './examResultsApi'
import filesApi from './filesApi'
import statisticApi from './statisticApi'
import productsApi from './productsApi'
import ordersApi from './ordersApi'
import studentsApi from './studentsApi'

const apis = {
	auth: authApi,
	subjects: subjectsApi,
	courses: coursesApi,
	courseSections: courseSectionsApi,
	lessons: lessonsApi,
	completedLessons: completedLessonsApi,
	users: usersApi,
	tests: testsApi,
	exams: examsApi,
	results: resultsApi,
	examResults: examResultsApi,
	files: filesApi,
	statistic: statisticApi,
	products: productsApi,
	orders: ordersApi,
	students: studentsApi,
}

export const apiFactory = {
	get: name => apis[name],
}