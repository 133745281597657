import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import SweetAlertVuetify from './plugins/SweetAlertVuetify'
import './plugins/vee-validate'
import './filters'
import jsme from './plugins/jsme' // TODO: remove this plugin in the future

import { ValidationObserver } from 'vee-validate'
import VTextFieldValidatable from './components/Inputs/VTextFieldValidatable'
import VAutocompleteValidatable from './components/Inputs/VAutocompleteValidatable'
import VTextareaValidatable from './components/Inputs/VTextareaValidatable'
import VCheckboxValidatable from './components/Inputs/VCheckboxValidatable'

Vue.config.productionTip = false

Vue.component('validation-observer', ValidationObserver)
Vue.component('v-text-field-validatable', VTextFieldValidatable)
Vue.component('v-autocomplete-validatable', VAutocompleteValidatable)
Vue.component('v-textarea-validatable', VTextareaValidatable)
Vue.component('v-checkbox-validatable', VCheckboxValidatable)

Vue.use(jsme)
Vue.use(SweetAlertVuetify, vuetify)

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app')
