import db from './db'

const store = db.subjects

export default {

	/**
	 * Put subject to db
	 * @param {Object} subject
	 */
	put(subject) {
		return store.put(subject)
	},

	/**
	 * Put all subjects to db
	 * @param {Array} posts
	 */
	putAll(subjects) {
		return store.bulkPut(subjects)
	},

	/**
	 * Get all subjects
	 */
	getAll() {
		console.error('🥬😳 this subjects got from indexeddb')
		return store.toArray()
	},
}