import api from './api'

const resource = '/results'

export default {
	
	create(payload) {
		return api.post(resource, payload)
	},

	/**
	 * Get Result by ID
	 *
	 * @param {Number} id Result ID
	 * @returns {Promise<any>} AxiosResponse
	 */
	get(id) {
		return api.get(`${resource}/${id}`)
	},
}