<template>
	<v-card
		elevation="24"
		@click="showDialog = true">
		<v-img
			:src="product.image"
			class="align-end"
			height="500px">
			<v-card
				class="ma-4"
				elevation="16">
				<v-card-title>
					{{ product.name }}
				</v-card-title>
				<v-card-title class="text-h4 font-weight-black">
					{{ product.price }}₴
				</v-card-title>
				<v-card-actions>
					<v-list-item class="grow">
						<v-list-item-avatar color="grey darken-3">
							<v-img
								class="elevation-6"
								alt=""
								src="https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&hairColor=BlondeGolden&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light">
							</v-img>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title>{{ author.name }}</v-list-item-title>
						</v-list-item-content>

						<v-spacer></v-spacer>

						<transition
							name="scale-transition"
							mode="out-in">
							<v-chip
								v-if="product.is_added_to_cart"
								key="chip"
								color="success"
								class="px-4"
								small>
								<v-icon left>mdi-check</v-icon>
								додано
							</v-chip>
							<v-btn
								v-else
								key="button"
								color="indigo accent-4"
								dark
								@click.stop="addToCart(product), playClick.play()">
								Купити
							</v-btn>
						</transition>
					</v-list-item>
				</v-card-actions>
			</v-card>
		</v-img>

		<v-dialog
			v-model="showDialog"
			max-width="500">
			<v-card>
				<v-img
					height="300"
					:src="product.image">
				</v-img>

				<v-card-title>
					{{ product.name }}

					<v-spacer></v-spacer>
					
					<v-chip color="success">
						<span class="font-weight-black font-italic">
							{{ product.price }}₴
						</span>
					</v-chip>
				</v-card-title>

				<v-card-text>
					<v-list-item class="grow">
						<v-list-item-avatar color="grey darken-3">
							<v-img
								class="elevation-6"
								alt=""
								src="https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&hairColor=BlondeGolden&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light">
							</v-img>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title>{{ author.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<div v-html="product.description"></div>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<transition
						name="scale-transition"
						mode="out-in">
						<v-chip
							v-if="product.is_added_to_cart"
							key="chip"
							color="success"
							class="px-4"
							small>
							<v-icon left>mdi-check</v-icon>
							додано
						</v-chip>
						<v-btn
							v-else
							key="button"
							color="indigo accent-4"
							dark
							@click="addToCart(product), playClick.play()">
							Купити
						</v-btn>
					</transition>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { useSound } from '@vueuse/sound'

import click from '../../assets/sound/click.mp3'

export default {
	props: {
		product: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		showDialog: false,
	}),
	computed: {
		course() {
			return this.product.courses[0] || {}
		},
		author() {
			return this.course.author || {}
		},
	},
	methods: {
		...mapActions('cart', [
			'addToCart',
		]),
	},
	setup() {
		const playClick = useSound(click)

		return {
			playClick,
		}
	},
}
</script>
