/* eslint-disable complexity */
import axios from 'axios'
import { stringify } from 'qs'
import {
	// handleResponseError,
	errorHandler,
} from './apiInterceptors'

// import savedRequests from '../db/savedRequests'

const api = axios.create({
	baseURL: process.env.VUE_APP_API,
})

/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
api.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}

		config.paramsSerializer = params => stringify(params)

		return config
	},
	(error) => {
		return Promise.reject(error)
	},
)

// api.interceptors.response.use(
// 	response => response,
// 	handleResponseError,
// )

api.interceptors.response.use(
	response => response,
	error => {
		console.warn('error.config.url', error.config.url)
		const { config } = error
		if (!error.status && config.method === 'post' && config._resend === true) {
			// const { url, method, data } = config
			// return savedRequests.store(url, method, data)
		// eslint-disable-next-line prefer-template
		} else if (error.response.status === 401 && error.config.url !== '/refresh' && error.config.url !== 'login') {
			return errorHandler(error)
		}
		return Promise.reject(error)
	},
)

// api.interceptors.response.use(
// 	response => response,
// 	error => {
// 		const config = error.config
// 		if (!error.status && config && config.method === "post" && config._resend === true) {
// 			console.error('🤯 This is network error', config)
// 			const { url, method, data } = config
// 			return savedRequests.store(url, method, data)
// 		} else if (error.response.status === 401 && error.config.url !== '/refresh' && error.config.url !== '/login') {
// 			return errorHandler(error)
// 		}
// 		return Promise.reject(error)
// 	},
// )

// api.interceptors.response.use(
// 	response => response,
// 	error => errorHandler(error),
// )

export default api