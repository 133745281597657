import db from './db'

const store = db.posts

export default {

	/**
	 * Put all posts to db
	 * @param {Array} posts
	 */
	putAll(posts) {
		return store.bulkPut(posts)
	},

	/**
	 * Get all posts
	 */
	getAll() {
		console.error('🥬😳 this posts got from indexeddb')
		return store.toArray()
	},
}