<template>
	<v-container>
		
		<section
			v-if="doShowProducts"
			class="mb-10">
			<h2>Курси</h2>
			<p class="grey--text">Наші чудові онлайн курси</p>

			<v-row>
				<v-col
					v-for="product in products"
					:key="product.id"
					cols="12"
					sm="6"
					md="4">
					<ProductCard
						:product="product">
					</ProductCard>
				</v-col>
			</v-row>
		</section>

		<section class="mb-10">
			<h2>Тести</h2>
			<p class="grey--text">Обери предмет і проходь тести</p>
			
			<div class="grid">
				<router-link v-for="subject in subjects"
					:key="subject.id"
					:to="{
						name: 'subject',
						params: {
							subjectid: subject.id,
						}
					}">
					<v-sheet>
						<v-img width="100%"
						height="100%"
							:src="subject.cover_img"
							cover/>
					</v-sheet>
					<h3>{{ subject.name }}</h3>
				</router-link>
			</div>
		</section>

		<section class="mb-10">
			<h2>Курси</h2>
			<p class="grey--text">Перелік твоїх курсів 🔥</p>

			<course-list :courses="courses"></course-list>

		</section>

		<!-- <section class="mb-10">
			<v-row>
				<v-col
					cols="12"
					md="6">
					<v-card
						:to="{
							name: 'test',
							params: {
								testid: 294,
							},
						}"
						color="green accent-1"
						elevation="0"
						light>
						<v-card-title
							class="success--text">
							Placement Test
						</v-card-title>
						<v-card-text>
							Пройди тест на знання англійської та дізнайся свій рівень!
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								:to="{
									name: 'test',
									params: {
										testid: 294,
									},
								}"
								color="success"
								text>
								Пройти тест
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
				<v-col
					cols="12"
					md="6">
					<v-card
						href="https://magistracy.brames.com.ua"
						target="blank"
						color="amber lighten-5"
						elevation="0"
						light>
						<v-card-title
							class="amber--text">
							ЗНО магістратура - курс підготовки
						</v-card-title>
						<v-card-text>
							Поглиблений курс підготовки до Єдиного вступного іспиту (ЄВІ) в Магістратуру з англійської мови (ЗНО-Магістратура)
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								href="https://magistracy.brames.com.ua"
								target="blank"
								color="amber"
								text>
								Дізнайся більше
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</section> -->

		<!-- <section class="mb-10">
			<h2>Активність</h2>
			<v-row>
				<v-col cols="12" sm="6">
					<v-card elevation="24" v-if="testCount">
						<v-card-text>
							<span class="display-2 font-weight-black font-italic">{{ testCount.total }} </span>
							<span> тестів пройдено</span>
						</v-card-text>
						<v-sparkline
							:value="testCountValues"
							color="#4DCB85"
							:smooth="10"
							:padding="0"
							:line-width="2"
							:fill="true"
							type="trend"
							auto-draw></v-sparkline>
					</v-card>
					<v-skeleton-loader
						v-else
						type="image">
					</v-skeleton-loader>
				</v-col>
			</v-row>
		</section> -->

		<!-- <bottom-sheet/> -->
		
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Subject from '../models/Subject'
import Course from '../models/Course'
import Product from '../models/Product'

import CourseList from '../components/Courses/CourseList'
import ProductCard from '../components/Products/ProductCard'

export default {
	components: {
		CourseList,
		ProductCard,
	},
	computed: {
		...mapGetters('auth', [
			'user',
			'isLoggedIn',
		]),
		...mapGetters('statistic', [
			'testCount',
		]),
		subjects() {
			return Subject.all()
		},
		courses() {
			return Course.all()
		},
		products() {
			return Product.all()
		},
		testCountValues() {
			if (this.testCount)
				return Object.values(this.testCount.dates)
			return []
		},

		/**
		 * Hide products section if user is studying in group
		 */
		doShowProducts() {
			if (this.isLoggedIn && this.user.isBelongsToGroup) {
				return false
			} else {
				return true
			}
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		// ...mapActions('statistic', [
		// 	'loadTestCount',
		// ]),

		async loadSubjects() {
			try {
				await Subject.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список твоїх предметів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadCourses() {
			try {
				await Course.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список твоїх курсів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadProducts() {
			try {
				await Product.fetchGetAll({
					filter: {
						has_courses: true,
					},
					include: [
						'courses.subject',
					],
				})
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список продуктів',
					color: 'error',
				}, { root: true })
				throw error
			}
		},
	},
	mounted() {
		if (this.isLoggedIn) {
			this.loadSubjects()
			this.loadCourses()
			// this.loadTestCount()
		}
		// this.loadProducts()
	},
}
</script>

<style>

.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
	grid-auto-rows: 1fr;
	grid-gap: 2em;
}

.grid::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.grid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

</style>
