import api from './api'

const resource = '/orders'

export default {

	/**
	 * Get all Orders
	 * @returns {Promise<any>} AxiosResponse
	 */
	getAll() {
		return api.get(`${resource}`)
	},

	/**
	 * Create new Order
	 * @param {Object} payload Order model
	 * @returns {Promise<any>} AxiosResponse
	 */
	create(payload) {
		return api.post(resource, payload)
	},

	/**
	 * Get by id
	 * @param {String} orderId
	 * @returns {Promise<any>} AxiosResponse
	 */
	get(orderId) {
		return api.get(`${resource}/${orderId}`)
	},

	/**
	 * Generate Order checkout data
	 * @param {String} orderId Order ID
	 * @returns {Promise<any>} AxiosResponse
	 */
	checkout(orderId) {
		return api.get(`${resource}/${orderId}/checkout`)
	},

}