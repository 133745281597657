<template>
	<validation-provider
		:name="$attrs.label"
		:rules="rules"
		v-slot="{ errors }">
		<v-autocomplete
			v-model="innerValue"
			:error-messages="errors"
			v-bind="$attrs"
			v-on="$listeners">
		</v-autocomplete>
	</validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
	components: {
		ValidationProvider,
	},
	props: {
		rules: {
			type: [Object, String],
			default: '',
		},
		// Must be included in props
		value: {
			type: null,
		},
	},
	data: () => ({
		innerValue: '',
	}),
	watch: {
		// Handles internal model changes
		innerValue(newVal) {
			this.$emit('input', newVal)
		},
		// Handles external model changes
		value(newVal) {
			this.innerValue = newVal
		},
	},
	created() {
		if (this.value) {
			this.innerValue = this.value
		}
	},
}
</script>
