import { apiFactory } from '../../api/apiFactory'

const usersApi = apiFactory.get('users')

const state = {
    users: [],
}

const getters = {
    users: state => state.users,
}

const mutations = {
    SET_USERS(state, payload) {
        state.users = payload
    },
}

const actions = {
    async loadUsers({ commit }) {
		try {
			const { data } = await usersApi.get()
			commit('SET_USERS', data)
		} catch (error) {
			console.error(error)
		}
	},
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
