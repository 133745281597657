import Home from '../views/Home.vue'

export default [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/index.html',
		redirect: '/',
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login.vue'),
		meta: {
			layout: 'auth-layout',
			bottomNav: false,
		},
	},
	{
		path: '/registration',
		name: 'registration',
		component: () => import('../views/Registration.vue'),
		meta: {
			layout: 'auth-layout',
			bottomNav: false,
		},
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: () => import('../views/auth/ForgotPassword.vue'),
		meta: {
			layout: 'auth-layout',
			bottomNav: false,
		},
	},
	{
		path: '/subjects/:subjectid(\\d+)',
		name: 'subject',
		component: () => import('../views/Subject.vue'),
		props: (route) => {
			return { subjectid: Number(route.params.subjectid) }
		},
		meta: {
			transitionName: 'slide',
			requiresAuth: true,
		},
	},
	// {
	// 	path: '/courses',
	// 	name: 'courses',
	// 	component: () => import('../views/Courses.vue'),
	// 	meta: {
	// 		requiresAuth: true,
	// 	},
	// },
	{
		path: '/courses/:courseid(\\d+)',
		name: 'course',
		component: () => import('../views/Course.vue'),
		props: (route) => {
			return { courseid: Number(route.params.courseid) }
		},
		meta: {
			transitionName: 'slide',
			requiresAuth: true,
		},
	},
	{
		path: '/course-sections/:courseSectionId(\\d+)',
		name: 'course-section',
		component: () => import('../views/CourseSection.vue'),
		props: ({ params }) => {
			return { courseSectionId: Number(params.courseSectionId) }
		},
		meta: {
			transitionName: 'slide',
			requiresAuth: true,
		},
	},
	{
		path: '/lessons/:lessonid(\\d+)',
		name: 'lesson',
		component: () => import('../views/Lesson.vue'),
		props: (route) => {
			return { lessonid: Number(route.params.lessonid) }
		},
		meta: {
			transitionName: 'slide',
			requiresAuth: true,
		},
	},
	{
		path: '/tests/:testid(\\d+)',
		name: 'test',
		component: () => import('../views/Test.vue'),
		props: (route) => {
			return { testid: Number(route.params.testid) }
		},
		meta: {
			bottomNav: false,
			requiresAuth: true,
		},
	},
	{
		path: '/exams/:testid(\\d+)',
		name: 'exam',
		component: () => import('../views/Exam.vue'),
		props: ({ params }) => {
			return { testid: Number(params.testid) }
		},
		meta: {
			bottomNav: false,
			requiresAuth: true,
		},
	},
	{
		path: '/cart',
		name: 'cart',
		component: () => import('../views/Cart.vue'),
		props: ({ query }) => {
			return {
				addProducts: query['add-products']?.split(',') || [],
			}
		},
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/orders',
		name: 'orders',
		component: () => import('../views/Orders.vue'),
		props: true,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/orders/:orderid',
		name: 'order',
		component: () => import('../views/Order.vue'),
		props: true,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/orders/:orderid/awaiting',
		name: 'order-awiting',
		component: () => import('../views/Order.vue'),
		props: ({ params }) => ({
			orderid: params.orderid,
			awaiting: true,
		}),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/students',
		name: 'students',
		component: () => import('../views/Students.vue'),
		meta: {
			requiresAuth: true,
			requiresRoles: [
				'teacher',
			],
		},
	},
	// {
	// 	path: '/results',
	// 	name: 'results',
	// 	component: () => import('../views/Results.vue'),
	// 	meta: {
	// 		requiresAuth: true,
	// 	},
	// },
	{
		path: '/results/:resultId(\\d+)',
		name: 'result',
		component: () => import('../views/Result.vue'),
		props: ({ params }) => {
			return { resultId: Number(params.resultId) }
		},
		meta: {
			requiresAuth: true,
			requiresRoles: [
				'teacher',
			],
		},
	},
	{
		path: '/settings',
		name: 'settings',
		component: () => import('../views/Settings.vue'),
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		component: () => import('../views/PrivacyPolicy.vue'),
		meta: {
			bottomNav: false,
		},
	},
	{
		path: '/offer-agreement',
		name: 'offer-agreement',
		component: () => import('../views/OfferAgreement.vue'),
		meta: {
			bottomNav: false,
		},
	},
]