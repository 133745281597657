<template>
	<v-app app>
		<component
			:is="layout">
		</component>

		<update-notify></update-notify>

		<notification></notification>
		<loader></loader>

	</v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { isDark } from './utils/theme'

import savedRequests from './db/savedRequests'

import DefaultLayout from './layouts/DefaultLayout'
import AuthLayout from './layouts/AuthLayout'

import UpdateNotify from './components/UpdateNotify'
import Notification from './components/Notification'
import Loader from './components/Loader'

export default {
	components: {
		DefaultLayout,
		AuthLayout,
		UpdateNotify,
		Notification,
		Loader,
	},
	computed: {
		...mapGetters('auth', [
			'isLoggedIn',
		]),
		isDark() {
			return isDark?.value
		},
		layout() {
			return this.$route.meta.layout || 'default-layout'
		},
	},
	methods: {
		...mapActions('auth', [
			'profile',
		]),
	},
	watch: {
		'isDark'(value) {
			this.$vuetify.theme.dark = value
		},
	},
	mounted() {
		if (this.isLoggedIn) {
			this.profile()
		}

		window.addEventListener('online', function() {
			console.warn('🥵🤢 resend all requests')
			savedRequests.resendAll()
		})
	},
}
</script>

<style>

html.dark {
	background-color: #000000;
}

.v-application--wrap,
.v-navigation-drawer,
.v-app-bar,
.v-snack {
	padding-top: constant(safe-area-inset-top) !important; /* iPhone X - iOS 11.0 */
	padding-top: env(safe-area-inset-top) !important; /* iPhone X - iOS 11.2 */
	padding-left: constant(safe-area-inset-left) !important;
	padding-left: env(safe-area-inset-left) !important;
	padding-right: constant(safe-area-inset-right) !important;
	padding-right: env(safe-area-inset-right) !important;
	padding-bottom: constant(safe-area-inset-bottom) !important;
	padding-bottom: env(safe-area-inset-bottom) !important;
}

.v-main__wrap,
.v-bottom-navigation,
.test-nav {
    padding-bottom: constant(safe-area-inset-bottom) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
}

.v-bottom-navigation,
.test-nav {
	height: auto !important;
}

.v-bottom-navigation .v-btn {
	height: 56px !important;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.v-card:before{
	border-radius: inherit !important;
}

.v-card__title {
	word-break: initial;
}

.cursor-pointer {
	cursor: pointer;
}

</style>
