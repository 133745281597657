/* eslint-disable camelcase */
import ApiModel from './ApiModel'

/**
 * Exam model
 */
class Exam extends ApiModel {

	static entity = 'exams'

	static apiResource = 'exams'

	static fields () {
		return {
			id: this.number(),
			name: this.string(),
			questions: this.attr([]),
		}
	}

	/**
	 * Get all Exams by Subject ID
	 * and insert response to Store
	 * @param {*} subjectid Subject ID
	 */
	static async fetchGetAllBySubject(subjectid) {
		const { data } = await this.api().getAllBySubject(subjectid)
		return this.create({ data })
	}
}

export default Exam