import User from './User'

/**
 * Student model
 */
class Student extends User {

	static entity = 'students'

	static apiResource = 'students'

}

export default Student