import api from './api'

export default {

	register(payload) {
		return api.post('register', payload)
	},

	login(payload) {
		return api.post('login', payload)
	},

	refresh() {
		return api.post('refresh')
	},

	logout() {
		return api.post('logout')
	},

	/**
	 * Get Auth User profile
	 *
	 * @returns {Promise<any>} AxiosResponse
	 */
	profile() {
		return api.get('profile')
	},

	sendResetLinkEmail(payload) {
		return api.post('password/email', payload)
	},
}