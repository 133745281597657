/* eslint-disable camelcase */
import ApiModel from './ApiModel'

/**
 * Course Section model
 */
class CourseSection extends ApiModel {

	static entity = 'courseSections'

	static apiResource = 'courseSections'

	static fields () {
		return {
			id: this.number(),
			name: this.string(null),
			course_id: this.number(null),
			lessons: this.attr([]),
			parent_id: this.attr(null),
			children: this.attr([]),
			created_at: this.string(null),
			updated_at: this.string(null),
		}
	}

	/**
	 * Get all Course Sections by Course ID
	 * and insert response to Store
	 * @param {Number} courseId Course ID
	 */
	static async fetchGetAllByCourse(courseId) {
		const { data } = await this.api().getAllByCourse(courseId)
		return this.create({ data })
	}
}

export default CourseSection