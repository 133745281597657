import ApiModel from './ApiModel'

/**
 * Product model
 */
class Product extends ApiModel {

	static entity = 'products'

	static apiResource = 'products'

	static fields () {
		return {
			id: this.number(),
			name: this.string(),
			price: this.number(),
			description: this.string(),
			image: this.string(),
			courses: this.attr([]),
			created_at: this.string(),
			updated_at: this.string(),
		}
	}

	/**
	 * Determine if Product is added to the Cart
	 */
	get is_added_to_cart() {
		return !!this.$store()?.getters['cart/items']?.find(({ id }) => id === this.id)
	}
}

export default Product