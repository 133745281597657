import ApiModel from './ApiModel'

const DEFAULT_STATUS = 'pending'

/**
 * Order model
 */
class Order extends ApiModel {

	static entity = 'orders'

	static apiResource = 'orders'

	static fields () {
		return {
			id: this.string(),
			total_amount: this.number(0),
			statuses: this.attr([]),
			status: this.string(null),
			order_products: this.attr([]),
			checkout_data: this.attr({}),
			created_at: this.string(),
			updated_at: this.string(),
		}
	}

	/**
	 * Determine if Order's status different from default
	 */
	get is_status_changed() {
		return this.status !== DEFAULT_STATUS
	}
}

export default Order