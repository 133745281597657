import api from './api'

const resource = '/course-sections'

export default {
	
	/**
	 * Get All Course Sections by Course ID
	 * @param {Number} courseId Course ID
	 */
	getAllByCourse(courseId) {
		return api.get(`/courses/${courseId}${resource}`)
	},
	
	/**
	 * Get Course Sections by ID
	 * @param {Number} courseSectionId Course Section ID
	 */
	get(courseSectionId) {
		return api.get(`${resource}/${courseSectionId}`)
	},

}