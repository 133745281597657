import ApiModel from './ApiModel'
import Subject from './Subject'

/**
 * Course model
 */
class Course extends ApiModel {

	static entity = 'courses'

	static apiResource = 'courses'

	static fields () {
		return {
			id: this.number(),
			name: this.string(),
			subject: this.hasOne(Subject),
			author_id: this.number(1),
			author: this.attr({}),
		}
	}
}

export default Course