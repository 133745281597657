import { apiFactory } from '../../api/apiFactory'
import { dbFactory } from '../../db/dbFactory'

const subjectsApi = apiFactory.get('subjects')
const subjectsDB = dbFactory.get('subjects')

const state = {
	subjects: [],
	subject: null,
}

const getters = {
    subjects: state => state.subjects,
    subject: state => state.subject,
}

const mutations = {
    SET_SUBJECTS(state, payload) {
        state.subjects = payload
    },
    SET_SUBJECT(state, payload) {
        state.subject = payload
    },
}

const actions = {
    async loadSubjects({ commit }) {
		try {
			const { data } = await subjectsApi.getAll()
			commit('SET_SUBJECTS', data)
			await subjectsDB.putAll(data)
		} catch (error) {
			console.error(error)
			try {
				const data = await subjectsDB.getAll()
				commit('SET_SUBJECTS', data)
			} catch (error) {
				console.error(error)
			}
		}
	},
	async loadSubject({ commit }, id) {
		try {
			const { data } = await subjectsApi.get(id)
			commit('SET_SUBJECT', data)
		} catch (error) {
			console.error(error)
		}
	},
	async createSubject(store, payload) {
		try {
			await subjectsApi.create(payload)
		} catch (error) {
			console.error(error)
		}
	},
	clearSubject({ commit }) {
		commit('SET_SUBJECT', null)
	},
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
