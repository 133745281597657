/**
 * Watch User and
 * check if user has non-empty cart on login
 */
export default store => {
	store.watch((state, getters) => getters['auth/isLoggedIn'], (isLoggedIn) => {
		if (isLoggedIn) {
			store.dispatch('cart/notifyCartNonEmpty')
		}
	})
}