import api from './api'

const resource = '/user'

export default {

    get() {
        return api.get(`${resource}`)
    },

    login(payload) {
		return api.post('login', payload)
	},
}