import { keyBy } from 'lodash'
import ApiModel from './ApiModel'

/**
 * User model
 */
class User extends ApiModel {

	static fields () {
		return {
			id: this.number(),
			name: this.string(),
			surname: this.string(),
			full_name: this.string(),
			email: this.string(),
			tel: this.string(),
			roles: this.attr([]),
			permissions: this.attr([]),
			groups: this.attr([]),
			results: this.attr([]),
			latest_results: this.attr([]),
		}
	}

	/**
	 * Get user's full name
	 */
	get fullname() {
		return `${this.name} ${this.surname}`
	}

	/**
	 * Get is User belongs to any Group
	 */
	get isBelongsToGroup() {
		return this.groups?.length > 0
	}

	/**
	 * Get keyed latest results
	 */
	get latest_results_keyed() {
		return keyBy(this.latest_results, 'test_id')
	}

	/**
	 * Check if user has specific role
	 * @param {String} role User
	 * @returns {Boolean}
	 */
	is(role) {
		return !!this.roles?.find(r => r?.slug === role)
	}
}

export default User