import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes/routes'
import store from './store/store'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

// eslint-disable-next-line max-statements, complexity
router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters['auth/isLoggedIn']) {
			if (to.matched.some(record => record.meta.requiresRoles)) {
				const user = store.getters['auth/user']
				const hasReqiredRole = to.matched.some(record => {
					return record.meta.requiresRoles.some(r => user.is(r))
				})
				if (hasReqiredRole) {
					return next()
				} else {
					return next('/login')
				}
			} else {
				return next()
			}
		}
		next('/login')
	} else {
		next()
	}
})

// router.beforeEach((to, from, next) => {

// 	let toBottomNav = to.meta.bottomNav
// 	let fromBottomNav = from.meta.bottomNav

// 	if (toBottomNav !== fromBottomNav) {
// 		if (toBottomNav === false) {
// 			store.dispatch('toggleBottomNav', false)
// 		} else {
// 			store.dispatch('toggleBottomNav', true)
// 		}
// 	}
// 	return next()
// })

export default router