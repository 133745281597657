import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import notification from './modules/notification'
import loader from './modules/loader'
import subjects from './modules/subjects'
import lessons from './modules/lessons'
import users from './modules/users'
import tests from './modules/tests'
import test from './modules/test'
import exam from './modules/exam'
import statistic from './modules/statistic'
import cart from './modules/cart'

// Plugins
import VuexORM from './plugins/vuex-orm'
import VuexPersist from './plugins/vuex-persist'

// Watchers
import watcher from './plugins/watcher'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		notification,
		loader,
		subjects,
		lessons,
		users,
		tests,
		test,
		exam,
		statistic,
		cart,
	},
	state: {
		darkMode: false,
		bottomNav: true,
	},
	getters: {
		darkMode: state => state.darkMode,
		bottomNav: state => state.bottomNav,
	},
	mutations: {
		SET_DARK_MODE(state, payload) {
			state.darkMode = payload
		},
		SET_BOTTOM_NAV(state, payload) {
			state.bottomNav = payload
		},
	},
	actions: {
		toogleDarkMode({ commit }, val=Boolean) {
			commit('SET_DARK_MODE', val)
		},
		toggleBottomNav({ commit }, val=Boolean) {
			commit('SET_BOTTOM_NAV', val)
		},
	},
	plugins: [
		VuexORM,
		VuexPersist,
		watcher,
	],
})