import api from './api'

const resource = '/students'

export default {
	
	/**
	 * Get All Students for Current Teacher
	 *
	 * @returns {Promise<any>} AxiosResponse
	 */
	getAll() {
		return api.get(`${resource}`)
	},
	
	/**
	 * Get Student by User Id
	 *
	 * @param {Number} id User Id
	 * @returns {Promise<any>} AxiosResponse
	 */
	get(id) {
		return api.get(`${resource}/${id}`)
	},

}
