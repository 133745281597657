import api from './api'

const resource = '/products'

export default {

	/**
	 * Get all products
	 * @param {Object} params Query params
	 * @returns {Promise<any>} AxiosResponse
	 */
	getAll(params) {
		return api.get(`${resource}`, {
			params,
		})
	},

	/**
	 * Get Product
	 * @param {Number} id Product ID
	 * @returns {Promise<any>} AxiosResponse
	 */
	get(id) {
		return api.get(`${resource}/${id}`)
	},
}