<template>
	<div>
		<div v-if="courses.length > 0"
			class="grid">
			<div v-for="course in courses" :key="course.id">
				<v-card
					height="100%"
					:to="{
						name: 'course',
						params: {
							courseid: course.id,
						}
					}"
					elevation="24">
					<v-card-title>{{ course.name }}</v-card-title>
					<v-card-text>
						<small>{{ course.author.name }} {{ course.author.surname }}</small>
					</v-card-text>
				</v-card>
			</div>
		</div>

		<div v-else
			class="grid">
			<div v-for="i in 2" :key="i">
				<v-skeleton-loader type="image"
				height="100%"></v-skeleton-loader>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		courses: {
			type: Array,
			required: true,
		},
	},
}
</script>