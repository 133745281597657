import api from './api'

const resource = '/exams'

export default {
	
	/**
	 * Get All Exams by Subject ID
	 * @param {Number} subjectid Subject ID
	 */
	getAllBySubject(subjectid) {
		return api.get(`/subjects/${subjectid}/exams`)
	},

	/**
	 * Get Exam by ID
	 * @param {Number} examid Exam ID
	 */
	get(examid) {
		return api.get(`${resource}/${examid}`)
	},

}