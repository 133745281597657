import postsDB from './postsDB'
import subjectsDB from './subjectsDB'

const apis = {
	posts: postsDB,
	subjects: subjectsDB,
}

export const dbFactory = {
    get: name => apis[name],
}