import { apiFactory } from '../../api/apiFactory'

const statisticApi = apiFactory.get('statistic')

const state = {
	testCount: null,
}

const getters = {
	testCount: ({ testCount }) => testCount,
}

const mutations = {
	SET_TEST_COUNT(state, payload) {
		state.testCount = payload
	},
}

const actions = {
	/**
	 * Load testCount
	 * @param {Object} param0 store
	 */
	async loadTestCount({ commit }) {
		try {
			const { data } = await statisticApi.getTestsCount()
			commit('SET_TEST_COUNT', data)
		} catch (error) {
			console.error(error)
		}
	},
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
