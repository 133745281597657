<template>
	<div>
		<v-app-bar
			app
			color="transparent"
			elevate-on-scroll
			fixed
			flat>
			<v-spacer></v-spacer>

			<v-btn
				v-if="!isLoggedIn"
				:to="{ name: 'login' }"
				color="btnBg"
				class="btnTxt--text"
				v-ripple="{ class: 'success--text' }"
				small>
				Увійти
			</v-btn>

		</v-app-bar>

		<!-- <v-navigation-drawer
			v-model="$vuetify.breakpoint.mdAndUp"
			app
			mobile-break-point="960">
			<v-container style="height: 2.7em;">
				<router-link to="/">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176 46" width="100%" height="100%">
						<path fill="" fill-rule="evenodd" d="M5.59 43.23C1.82 40.8.45 36.53.22 32.53L.4 0h5.19v23.1c3.53-3.8 8.07-4.23 10.24-4.23 9.37 0 14.56 6.7 14.56 13.66C30.39 39.6 25.13 46 16.04 46c-4.83 0-7.18-.82-10.45-2.77zm9.73-1.2c6.06 0 9.73-4.35 9.73-9.5 0-6.33-4.97-9.69-9.8-9.69-4.83 0-9.95 3.42-9.95 9.63 0 5.27 3.9 9.56 10.02 9.56zm18.44 3.23V19.49h5.19v2.8a8.52 8.52 0 016.78-3.42c1.58 0 3.31.5 4.6 1.06l-2.44 4.03a5.95 5.95 0 00-3.03-.87c-2.09 0-3.39.75-4.18 1.62-.87.99-1.73 2.73-1.73 6.95v13.6h-5.2zm38.19-22.1v-3.67h5.19v25.77h-5.2v-3.6C70.23 43.57 66.98 46 61.72 46c-7.57 0-14.56-4.6-14.56-13.66 0-8.87 6.92-13.47 14.56-13.47 5.77 0 8.87 2.73 10.24 4.29zm-9.66-.32c-5.12 0-9.8 3.23-9.8 9.5 0 5.53 3.6 9.69 9.8 9.69 6.34 0 9.95-4.53 9.95-9.63 0-6.64-5.34-9.56-9.95-9.56zm20.1 22.42V19.49h5.18v2.73c1.66-2.1 4.26-3.35 7.43-3.35 5.4 0 7.57 3.3 8.15 4.16 2.88-3.91 6.99-4.16 9-4.16 8.87 0 9.74 6.4 9.74 10.06v16.33h-5.2V29.6c0-2.92-.42-6.77-5.33-6.77-5.98 0-6.63 4.97-6.63 8.07v14.35h-5.19V29.98c0-1.8.07-7.14-5.4-7.14-5.92 0-6.57 5.28-6.57 8.39v14.03h-5.19zm67.02-8.63l4.32 2.04a15.9 15.9 0 01-3.67 4.16A15.96 15.96 0 01140.1 46c-7.2 0-14.78-3.91-14.78-13.47 0-7.7 5.48-13.66 14.5-13.66 5.83 0 9.5 2.48 11.31 4.72 1.87 2.3 2.96 6.27 2.81 9.68h-23.28c.07 5.34 4.32 8.76 9.51 8.76a10.03 10.03 0 009.23-5.4zm-18.38-7.08h17.59c-.87-4.04-4.4-6.7-8.8-6.7s-8 2.85-8.8 6.7zm42.87-6.02l-4.25 1.92a4.58 4.58 0 00-4.18-2.6c-1.88 0-3.6.93-3.6 2.91 0 1.86 1.43 2.42 4.82 3.67 4.33 1.55 6.35 2.73 7.5 3.97a5.56 5.56 0 011.59 4.16c0 5.4-4.55 8.44-10.31 8.44-1.23 0-7.93-.12-10.46-6.58l4.47-1.61c.58 1.36 2.31 4.22 6.06 4.22 3.39 0 4.9-2.18 4.9-4.1 0-2.42-2.02-3.23-4.83-4.28-3.6-1.37-5.91-2.36-7.28-3.79a5.08 5.08 0 01-1.37-3.79c0-4.53 3.68-7.2 8.72-7.2 1.59 0 5.84.25 8.22 4.66z"/>
					</svg>
				</router-link>
			</v-container>
			<v-list dense>
				<v-list-item
					v-for="item in menuItems"
					:key="item.text"
					:to="item.path"
					color="primary">
					<v-list-item-action>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>
						{{ item.text }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer> -->

		<v-main>
			<transition
				:name="transitionName"
				mode="out-in">
				<router-view/>
			</transition>
		</v-main>

		<v-bottom-navigation
			app
			fixed
			grow>
			<!-- <v-bottom-navigation
			v-if="isLoggedIn && bottomNav"
			app
			fixed
			grow
			class="d-md-none"> -->
			<v-btn :to="{ name: 'home' }" color="primary" text exact class="px-0 v-icon">
				<small>Домівка</small>
				<v-icon>mdi-home-variant</v-icon>
			</v-btn>


			<v-btn
				v-if="!isEmpty"
				:to="{ name: 'cart' }"
				color="primary"
				class="px-0 v-icon"
				exact
				text>
				<small>Кошик</small>
				<v-badge
					color="red"
					:content="productsQuantity">
					<v-icon>mdi-cart-variant</v-icon>
				</v-badge>
			</v-btn>

			<v-btn
				v-else
				:to="{ name: 'orders' }"
				color="primary"
				class="px-0 v-icon"
				exact
				text>
				<small>Замовлення</small>
				<v-icon>mdi-cart-variant</v-icon>
			</v-btn>

			<v-btn
				v-if="user.is('teacher')"
				:to="{ name: 'students' }"
				color="primary"
				class="px-0 v-icon"
				exact
				text>
				<small>Студенти</small>
				<v-icon>mdi-account-group</v-icon>
			</v-btn>

			<!-- <v-btn :to="{ name: 'courses' }" color="primary" text class="px-0">
				<small>Курси</small>
				<v-icon>mdi-video-check</v-icon>
			</v-btn> -->

			<!-- <v-btn :to="{ name: 'tests', params: { subjectid: 1 } }" color="primary" text class="px-0">
				<small>Тести</small>
				<v-icon>mdi-file-document-box-check</v-icon>
			</v-btn> -->

			<!-- <v-btn :to="{ name: 'results' }" color="primary" text class="px-0">
				<small>Результати</small>
				<v-icon>mdi-chart-bar-stacked</v-icon>
			</v-btn> -->

			<v-btn :to="{ name: 'settings' }" color="primary" text class="px-0">
				<small>Опції</small>
				<v-icon>mdi-cog</v-icon>
			</v-btn>

			<v-menu
				v-if="isLoggedIn"
				transition="scale-transition"
				origin="bottom right"
				offset-y
				left
				top>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						depressed
						small
						fab>
						<small>Акаунт</small>
						<v-icon>mdi-account</v-icon>
					</v-btn>
				</template>

				<v-list class="pa-0">
					<v-list-item
						:to="{ name: 'orders' }">
						<v-list-item-title>Замовлення</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-btn
							color="error"
							text
							small
							@click="doLogout">
							вийти
						</v-btn>
					</v-list-item>
				</v-list>
			</v-menu>

		</v-bottom-navigation>

	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

const DEFAULT_TRANSITION = 'slide-right'

export default {
	data: () => ({
		transitionName: DEFAULT_TRANSITION,
		drawer: false,
		menuItems: [
			{
				text: 'Домівка',
				path: { name: 'home' },
				icon: 'mdi-home-variant',
			},
			{
				text: 'Курси',
				path: { name: 'courses' },
				icon: 'mdi-video-check',
			},
			{
				text: 'Тести',
				path: { name: 'tests', params: { subjectid: 1 } },
				icon: 'mdi-file-document-box-check',
			},
			{
				text: 'Результати',
				path: { name: 'results' },
				icon: 'mdi-chart-bar-stacked',
			},
			{
				text: 'Опції',
				path: { name: 'login' },
				icon: 'mdi-settings',
			},
		],
	}),
	computed: {
		...mapGetters([
			'bottomNav',
		]),
		...mapGetters('auth', [
			'user',
			'isLoggedIn',
		]),
		...mapGetters('cart', [
			'isEmpty',
			'productsQuantity',
		]),
	},
	methods: {
		...mapActions('auth', [
			'logout',
		]),
		async doLogout() {
			const success = await this.logout()
			if (success) this.$router.push({ name: 'home' })
		},
	},
	created() {
		this.$router.beforeEach((to, from, next) => {
			let transitionName = to.meta.transitionName || from.meta.transitionName
			
			if (transitionName === 'slide') {
				const toDepth = to.path.split('/').length
				const fromDepth = from.path.split('/').length
				transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
			}
			
			this.transitionName = transitionName || DEFAULT_TRANSITION
			
			next()
		})
	},
}
</script>
