/* eslint-disable camelcase */

// TODO: Refactor exams

import vue from 'vue'
import { apiFactory } from '../../api/apiFactory'

const examsApi = apiFactory.get('exams')
const resultsApi = apiFactory.get('examResults')

const state = {
	test: null,
	activeIndex: null,
	result: null,
}

const getters = {
	test: state => state.test,
	activeIndex: state => state.activeIndex,
	activeQuestion: state => {
		if (state.test) return state.test.questions[state.activeIndex]
		return null
	},
	answeredQuestions: ({ test }) => {
		if (test) {
			return test.questions.filter(item => item.show_result).map(item => item.id)
		}
		return null
	},
	correctAnsweredQuestions: ({ test }) => {
		if (test) {
			return test.questions.filter(item => item.result).map(item => item.id)
		}
		return null
	},
	result: ({ result }) => result,
}

const mutations = {
    SET_TEST(state, payload) {
        state.test = payload
	},
	SET_ACTIVE_INDEX(state, payload) {
		state.activeIndex = payload
	},
	UPDATE_QUESTION(state, payload) {
		const question = state.test.questions[state.activeIndex]
		vue.set(state.test.questions, state.activeIndex, { ...question, ...payload })
	},
	SET_RESULT(state, payload) {
		state.result = payload
	},
}

const actions = {
	async loadTest({ commit }, testid) {
		console.warn('LOAD TEEEEST', testid)
		try {
			const { data } = await examsApi.get(testid)
			commit('SET_TEST', data)
			commit('SET_ACTIVE_INDEX', 0)
		} catch (error) {
			console.error(error)
		}
	},
	changeQuestion({ commit }, index) {
		commit('SET_ACTIVE_INDEX', index)
	},
	updateQuestion({ commit }, payload) {
		commit('UPDATE_QUESTION', payload)
	},
	checkQuestion({ commit }) {
		commit('UPDATE_QUESTION', {
			show_result: true,
		})
	},
	async saveResult({ commit, getters }) {
		try {
			const result = (getters['correctAnsweredQuestions'].length / getters['test'].questions.length * 100).toFixed(0)
			const { test } = getters
			const exam_id = test.id
			const { data } = await resultsApi.create({ exam_id, result, data: test })
			return commit('SET_RESULT', data)
		} catch (error) {
			console.error(error)
		}
	},
	clearTest({ commit }) {
		commit('SET_TEST', null)
	},
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
