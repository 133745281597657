import api from './api'

const resource = '/files'

export default {

    get(id) {
        return api.get(`${resource}/${id}`, {
			responseType: 'blob',
		})
    },
}