import api from './api'

const resource = '/tests'

export default {

	get() {
		return api.get(`${resource}`)
	},
	
	/**
	 * Get subject by id
	 * @param {Number} subjectid
	 */
	getBySubject(subjectid) {
		return api.get(`/subjects/${subjectid}/tests`)
	},

	getTest(testid) {
		return api.get(`${resource}/${testid}`)
	},

	checkTest(testid, payload) {
		return api.post(`${resource}/${testid}/check`, payload)
	},
}