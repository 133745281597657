import { apiFactory } from '../../api/apiFactory'

const testsApi = apiFactory.get('tests')

const state = {
	tests: [],
}

const getters = {
	tests: state => state.tests,
}

const mutations = {
    SET_TESTS(state, payload) {
        state.tests = payload
    },
}

const actions = {
	async loadTestsBySubject({ commit }, subjectid) {
		try {
			const { data } = await testsApi.getBySubject(subjectid)
			commit('SET_TESTS', data)
		} catch (error) {
			console.error(error)
		}
	},
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
