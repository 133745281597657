import api from '../../api/api'
import { apiFactory } from '../../api/apiFactory'
import User from '../../models/User'

const authApi = apiFactory.get('auth')

const state = {
	status: '',
	token: localStorage.getItem('token') || '',
	user: {},
}

const getters = {
	isLoggedIn: state => !!state.token,
	status: state => state.status,
	token: state => state.token,
	user: state => new User(state.user),
}

const mutations = {
	SET_STATUS(state, status) {
		state.status = status
	},
	SET_TOKEN(state, token) {
		state.token = token
	},
	SET_USER(state, user) {
		state.user = user
	},
	LOGOUT(state){
		state.status = ''
		state.token = ''
		state.user = {}
	},
}

const actions = {
	async register({ dispatch }, payload) {
		try {
			const { data } = await authApi.register(payload)
			const { token } = data
			dispatch('saveToken', token)
			dispatch('notify', {
				text: 'Ура! Тебе зареєстрували 🔥',
				color: 'success',
			}, { root: true })
			return data
		} catch (error) {
			dispatch('notify', {
				text: 'Не вдалося зареєструватись 🙁',
				color: 'error',
			}, { root: true })
			throw error
		}
	},
	async login({ dispatch }, payload) {
		try {
			const { data } = await authApi.login(payload)
			const { token, user } = data
			dispatch('saveToken', token)
			dispatch('saveUser', user)
			dispatch('notify', {
				text: 'Ура! Ти в системі 😎',
				color: 'success',
			}, { root: true })
			return data
		} catch (error) {
			dispatch('notify', {
				text: 'Не вдалося увійти 😓',
				color: 'error',
			}, { root: true })
			throw error
		}
	},
	async logout({ commit }) {
		try {
			await authApi.logout()
			commit('LOGOUT')
			localStorage.removeItem('token')
			localStorage.removeItem('user')
			delete api.defaults.headers.common['Authorization']
			return true
		} catch (error) {
			console.error(error)
		}
	},

	async profile({ dispatch }, payload) {
		try {
			const { data } = await authApi.profile(payload)
			dispatch('saveUser', data)
			return data
		} catch (error) {
			dispatch('notify', {
				text: 'Не вдалося завантажити твої дані 😓',
				color: 'error',
			}, { root: true })
			throw error
		}
	},

	async sendResetLinkEmail({ dispatch }, payload) {
		try {
			const { data } = await authApi.sendResetLinkEmail(payload)
			const { message } = data
			dispatch('notify', {
				text: message,
				color: 'success',
			}, { root: true })
			return data
		} catch (error) {
			dispatch('notify', {
				text: 'Не вдалося скинути пароль 🙁',
				color: 'error',
			}, { root: true })
			throw error
		}
	},
	saveToken({ commit }, token) {
		commit('SET_TOKEN', token)
		localStorage.setItem('token', token)
		api.defaults.headers.common['Authorization'] = `Bearer ${token}`
	},
	saveUser({ commit }, user) {
		commit('SET_USER', user)
		localStorage.setItem('user', JSON.stringify(user))
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}