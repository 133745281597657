import Product from './Product'

/**
 * Cart Product model
 */
class CartProduct extends Product {

	static entity = 'cartProducts'

	static fields () {
		return {
			...super.fields(),
			quantity: this.number(0),
		}
	}

	/**
	 * Get total amount for CartProduct
	 */
	get total_amount() {
		return this.price * this.quantity
	}

	/**
	 * Check if CartProduct has associated courses
	 */
	get has_courses() {
		return this.courses?.length > 0
	}

	/**
	 * Get max quantity of supplied CartProduct
	 */
	get max_quantity() {
		return this.has_courses
			? 1
			: undefined
	}

	/**
	 * Check if CartProduct is quantifiable
	 */
	get is_quantifiable() {
		return this.max_quantity !== 1
	}

	/**
	 * Validate and set CartProduct quantity
	 * @param {Number} value quantity
	 */
	setQuantity(value = 1) {

		if (value < 1) {
			throw new Error('Не можна ввести кількість менше одиниці')
		}

		if (this.max_quantity && value > this.max_quantity) {
			throw new Error('Кількість перевищує максимально допустиму')
		}

		this.quantity = value
	}

	/**
	 * Increment CartProduct quantity
	 */
	increment() {
		this.setQuantity(this.quantity + 1)
		this.$save()
	}

	/**
	 * Decrement CartProduct quantity
	 */
	decrement() {
		this.setQuantity(this.quantity - 1)
		this.$save()
	}
}

export default CartProduct